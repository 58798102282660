<template>
  <div>
    Page Not Found
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  created () {

  }
}
</script>

<style scoped>

</style>
